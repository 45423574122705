import Axios from 'axios';
import VueCookies from 'vue-cookies';
import { createToast } from 'mosha-vue-toastify';
import router from '../router';

const APIBaseURL = process.env.VUE_APP_BACKEND_URL;

const adapter = Axios.create({
  baseURL: APIBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const monitoringAdapter = Axios.create({
  baseURL: process.env.VUE_APP_MONITORING_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_MONITORING_KEY,
  },
});

const shortenerAdapter = Axios.create({
  baseURL: process.env.VUE_APP_MYDID_URL_SHORTENER_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_MYDID_URL_SHORTENER_KEY,
  },
});

const badgeGeneratorAdapter = Axios.create({
  baseURL: process.env.VUE_APP_BADGE_GENERATOR_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_BADGE_GENERATOR_KEY,
  },
});

adapter.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

badgeGeneratorAdapter.interceptors.request.use(
  (config) => {
    config.headers['Token'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function eachRecursive(obj) {
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k]);
    else if (obj[k] == null) obj[k] = '';
  }
}

async function broadcastTransaction(body) {
  return adapter.post(`/broadcaster/transactions`, body);
}

async function getTransactionStatus(transactionId) {
  return adapter.get(`/broadcaster/transactions/${transactionId}`);
}

async function getPendingTransactions() {
  return adapter.get(`/issuers/transactions`);
}

async function getIssuerTemplates() {
  return adapter.get('/issuers/templates');
}

async function getIssuer(did) {
  return adapter.get('/issuers/issuer/' + did);
}

async function updateIssuer(body) {
  return adapter.put('/issuers/issuer/', body);
}

async function syncTemplate(hash) {
  return adapter.post('/operations/sync-template', { hash });
}

async function getSessionsForTemplate(templateHash) {
  return badgeGeneratorAdapter.get(`/auth/sessions/` + templateHash);
}

async function getSessionsForTemplates(templateList) {
  return badgeGeneratorAdapter.post(`/auth/sessions/multi`, templateList);
}

export default {
  install(app) {
    adapter.interceptors.response.use(
      (response) => {
        eachRecursive(response.data);
        return response;
      },
      (error) => {
        if (error.reponse) {
          if (
            // error.response.status === 404 ||
            error.response.status === 401 ||
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            VueCookies.remove('token');
            router.push('/');
          }
        }

        if (error.message === 'Network Error') {
          console.log("Can't reach server");
          createToast(`Can't reach server, please try again later`, {
            position: 'bottom-center',
            hideProgressBar: true,
            timeout: 3000,
            transition: 'slide',
            toastBackgroundColor: '#ff4545',
          });
          VueCookies.remove('token');
          router.push('/signin');
        }

        return Promise.reject(error);
      }
    );

    var evtSource = null;

    var api = {
      uploadIssuer(jsonString, file) {
        var data = new FormData();
        data.append('file', file);
        data.append('data', jsonString);

        var config = {
          method: 'post',
          url: process.env.VUE_APP_BACKEND_URL + '/operations/upload-issuer',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: VueCookies.get('token')
              ? 'Bearer ' + VueCookies.get('token')
              : '',
          },
          data,
        };

        return Axios(config);
      },
      uploadTemplate(jsonString, file, updatedHash) {
        console.log('uploadTemplate method - file', file);
        console.log('uploadTemplate method - jsonString', jsonString);
        blobToBase64(file)
          .then((base64String) => {
            console.log(
              'uploadTemplate method - convert file to base64',
              base64String
            ); // Outputs full data:type;base64 string
          })
          .catch((error) => {
            console.error('Error converting blob to base64:', error);
          });
        var data = new FormData();
        data.append('file', file);
        data.append('data', jsonString);
        if (updatedHash) data.append('updatedHash', updatedHash);

        var config = {
          method: 'post',
          url: process.env.VUE_APP_BACKEND_URL + '/operations/upload-template',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: VueCookies.get('token')
              ? 'Bearer ' + VueCookies.get('token')
              : '',
          },
          data,
        };

        return Axios(config);
      },
      deleteTemplate(hash) {
        return adapter.delete('/operations/delete-template/' + hash);
      },
      queueOperation(type, data) {
        return adapter.post('/operations', {
          type,
          data,
        });
      },
      getIssuerTemplates,
      isTimeExpired(time) {
        return adapter.get(`/auth/time-info?time=${time}`);
      },
      addTransaction(hash, action, did) {
        return adapter.post('/issuers/transactions', {
          hash,
          action,
          did,
        });
      },
      getOperation(id) {
        return adapter.get('/operations/' + id);
      },
      addIpfsData(body) {
        return adapter.post('/ipfs-data/', body);
      },
      createIssuer(body) {
        return adapter.post('/issuers/', body);
      },
      isAdminForIssuer(issuerDid) {
        return adapter.get('/issuers/is-admin/' + issuerDid);
      },
      getIssuers() {
        return adapter.get('/issuers/');
      },
      getIssuer,
      updateIssuer,
      syncTemplate,
      getPendingTransactions,
      isIssuerRegistered(did) {
        return adapter.get('/issuers/issuer/registered/' + did);
      },
      disableIssuer() {
        return adapter.put('/issuers/disable');
      },
      getIssuersForUser() {
        return adapter.get('/issuers/user/');
      },
      getIssuerTransactions(fromHash, number) {
        return adapter.get('/issuers/transactions/', {
          params: { fromHash, number },
        });
      },
      getCitiesFromQuery(countryCode, query) {
        return adapter.get(`/cities/query/${countryCode}/${query}`);
      },
      getCityFromDetails(country, name) {
        return adapter.get(`/cities/details/${country}/${name}`);
      },
      createAuthChallenge() {
        return adapter.post('/auth/challenge/');
      },
      submitAuthVP(body) {
        return adapter.post('/auth/vp/', body);
      },
      signNostrChallenge(body) {
        return adapter.post('/auth/nostr/sign', body);
      },
      waitChallengeValidation(challenge, next) {
        evtSource = new EventSource(APIBaseURL + '/auth/sse/' + challenge, {
          xhrHeaders: {
            'Content-Type': 'text/event-stream',
            Connection: 'keep-alive',
          },
        });

        evtSource.addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data).data;
            console.log('Receive elements from stream : ', response);
            if (
              response.status == 'validated' &&
              response.challenge == challenge
            ) {
              console.log('Great, you did sign in');
              evtSource.close();
              return next(response);
            } else if (response.status == 'expired') {
              console.log('Challenge expired, you did not sign in');
              evtSource.close();
              return next(response);
            } else {
              next(response);
            }
          },
          false
        );

        evtSource.addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return evtSource;
      },
      closeChallengeValidation() {
        if (evtSource) evtSource.close();
      },
      getStatistics(fromDate, toDate) {
        return monitoringAdapter.get(
          `/events?fromDate=${fromDate}&toDate=${toDate}`
        );
      },
      createSessionBuilder(body) {
        return badgeGeneratorAdapter.post(`/auth/sessions/builders`, body);
      },
      getSessionBuilders(templateHash) {
        return badgeGeneratorAdapter.get(
          `/auth/sessions/builders/` + templateHash
        );
      },
      createSession(body) {
        return badgeGeneratorAdapter.post(`/auth/sessions`, body);
      },
      retriggerExpiredSession(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions/resend`, body);
      },
      updateSession(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions`, body);
      },
      updateSessionNote(body) {
        return badgeGeneratorAdapter.put(`/auth/sessions/note`, body);
      },
      sendOpenSessionByEmail(body) {
        return badgeGeneratorAdapter.post(`/auth/sessions/send`, body);
      },
      getSessionsForTemplate,
      getSessionsForTemplates,
      revokeAllSessionsForTemplate(templateHash) {
        return badgeGeneratorAdapter.post(`/auth/sessions/revoke`, {
          templateHash,
        });
      },
      shortenLink(link) {
        return shortenerAdapter.post(`/firebase/create`, {
          urlData: link,
        });
      },
      broadcastTransaction,
      getTransactionStatus,
      createSubscriptionForIssuer(body) {
        return adapter.post('/submanager/subscriptions/', body);
      },
      getSubscriptionStatus(id) {
        return adapter.get('/submanager/subscriptions/' + id);
      },
    };
    app.provide('api', api);
  },
};

export {
  broadcastTransaction,
  getTransactionStatus,
  getSessionsForTemplate,
  getSessionsForTemplates,
  getIssuerTemplates,
  getIssuer,
  updateIssuer,
  syncTemplate,
  getPendingTransactions,
};

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result); // Return the full data URL
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(blob); // Reads the blob as a DataURL (base64 encoded)
  });
}
