import bs58 from 'bs58';
import Axios from 'axios';
import { unixfs } from '@helia/unixfs';
import { BlackHoleBlockstore } from 'blockstore-core/black-hole';
import { fixedSize } from 'ipfs-unixfs-importer/chunker';
import { balanced } from 'ipfs-unixfs-importer/layout';

export function fileToUint8Array(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const arrayBuffer = event.target.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      resolve(uint8Array);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
}

export const calculateCid = async (bytes) => {
  const unixFs = unixfs({
    blockstore: new BlackHoleBlockstore(),
  });

  const cid = await unixFs.addBytes(bytes, {
    cidVersion: 0,
    rawLeaves: false,
    leafType: 'file',
    layout: balanced({
      maxChildrenPerNode: 174,
    }),
    chunker: fixedSize({
      chunkSize: 262144,
    }),
  });

  const cidv0 = cid.toV0().toString();
  return cidv0;
};

export async function getJsonDataFromUrl(url) {
  if (
    !url.startsWith('https://myntfsid.mypinata.cloud/') &&
    !url.startsWith('https://resolver.mydid.eu/') &&
    !url.startsWith('https://get.webstorage.mydid.com/') &&
    !url.startsWith(process.env.VUE_APP_MYDID_IPFS_BALANCER_URL)
  )
    throw 'Bad URL input for method : getJsonDataFromUrl';

  if (
    url.startsWith('https://myntfsid.mypinata.cloud/') ||
    url.startsWith('https://get.webstorage.mydid.com/')
  ) {
    url = url.replace(
      /^https:\/\/myntfsid\.mypinata\.cloud\/|^https:\/\/get\.webstorage\.mydid\.com\//,
      process.env.VUE_APP_MYDID_IPFS_BALANCER_URL.replace('/ipfs', '/')
    );
  }

  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function getJsonDataFromCID(cid) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_MYDID_IPFS_BALANCER_URL}/${cid}`)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function hashToCID(hash) {
  const cleanHash = (hash + '').replace('0x', '');
  const bytes = Buffer.from('1220' + cleanHash, 'hex');
  const cid = bs58.encode(bytes);
  return cid;
}

export function cidToHash(cid) {
  const bytes = bs58.decode(cid.substring(0, 46));
  const hashHex = bytes.toString('hex').substring(4); // Remove the '1220' prefix (4 hex characters)
  return '0x' + hashHex;
}

export function getUrlFromCID(cid) {
  return `https://myntfsid.mypinata.cloud/ipfs/${cid}`;
}

export async function uploadIpfsFile(file) {
  var data = new FormData();
  data.append('file', file);

  var config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
    },
    data,
  };
  const res = await Axios(config);
  const hash = bs58
    .decode(res.data.IpfsHash.substring(0, 46))
    .toString('hex')
    .substring(4);
  return hash;
}

export async function uploadIpfsJsonData(data) {
  var config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
    },
    data,
  };
  const res = await Axios(config);
  const hash = bs58
    .decode(res.data.IpfsHash.substring(0, 46))
    .toString('hex')
    .substring(4);
  return hash;
}
