import Web3 from 'web3';
import bluebirdPromise from 'bluebird';
import mydidContractABI from '/assets/mydidContractABI.json';
import {
  getJsonDataFromUrl,
  getJsonDataFromCID,
  hashToCID,
  cidToHash,
} from '../ipfs';
import createKeccakHash from 'keccak';
import {
  getSessionsForTemplates,
  getIssuerTemplates,
  getIssuer,
  updateIssuer,
  syncTemplate,
} from '../../api';
import { ColorPicker } from 'vue-color-kit';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

const mydidContract = new web3.eth.Contract(
  mydidContractABI,
  process.env.VUE_APP_MYDID_CONTRACT_ADDR
);

export async function getIssuerDetails(did) {
  const issuerInfo = (await getIssuer(did)).data;
  let issuerProfile;

  const profileService = await getJsonDataFromUrl(
    `https://resolver.mydid.eu/1.0/identifiers/${did}?tag=SERV_1&chainId=${process.env.VUE_APP_CHAIN_ID}`
  );
  if (!profileService.serviceEndpoint || issuerInfo.state == 'updating') {
    // issuer does not exist yet on contract OR is updating : use db issuer hash to retrieve profile
    if (!issuerInfo.hash) throw 'No hash on none-contract issuer';
    issuerProfile = await getJsonDataFromCID(hashToCID(issuerInfo.hash));
  } else {
    // issuer exists on contract : use resolver info to retrieve profile
    const issuerContractHash = cidToHash(
      profileService.serviceEndpoint.split('/')[
        profileService.serviceEndpoint.split('/').length - 1
      ]
    ).replace('0x', '');
    issuerProfile = await getJsonDataFromUrl(profileService.serviceEndpoint);

    if (!issuerInfo.hash || issuerInfo.hash != issuerContractHash) {
      // issuer is older version or is desync : update it (add hash) and mirror ipfs with webstorage
      console.log('Trigger resync for issuer');
      await updateIssuer({
        hash: issuerContractHash,
      });
    }
  }

  return Object.assign(issuerInfo, { profile: issuerProfile });
}

export async function getTemplateListNew(addr) {
  let dbTemplates = (await getIssuerTemplates()).data;

  const contractTemplateList = (
    await Promise.all(
      [0, 1, 2, 3, 4].map((index) =>
        mydidContract.methods.getIssuerTemplates(addr, index).call()
      )
    )
  ).flat();

  let templateList = [];

  // handle published template
  for (let contractTemplate of contractTemplateList) {
    if (
      contractTemplate.templateHash ==
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
      continue;

    // check if there is corresponding template in DB
    const correspondingDbTemplate = dbTemplates.find(
      (dbTemplate) =>
        contractTemplate.templateHash.replace('0x', '') ==
        dbTemplate.hash.replace('0x', '')
    );

    if (correspondingDbTemplate && !correspondingDbTemplate.contractState) {
      // old version of template : force sync
      correspondingDbTemplate = null;
    }

    if (
      correspondingDbTemplate &&
      correspondingDbTemplate.contractState == 'deleting'
    )
      continue;

    if (!correspondingDbTemplate) {
      // db is desync, create new template in db
      console.log(
        `Trigger resync for template with hash ${contractTemplate.templateHash}`
      );
      syncTemplate(contractTemplate.templateHash.replace('0x', ''));
      
      templateList.push({
        templateHash: contractTemplate.templateHash.replace('0x', ''),
        visibility: 'public',
        contractState: 'stored',
        callbackId: null,
        name: null,
        status: {
          timestamp: Number(contractTemplate.status.timestamp),
          value: Number(contractTemplate.status.value),
        },
        index: Number(contractTemplate.index),
        sessions: [],
        data: {},
      });
    } else {
      templateList.push({
        templateHash:
          ['updating', 'creating'].indexOf(
            correspondingDbTemplate.contractState
          ) != -1
            ? correspondingDbTemplate.updatingHash.replace('0x', '')
            : contractTemplate.templateHash.replace('0x', ''),
        visibility: correspondingDbTemplate.visibility,
        contractState: correspondingDbTemplate.contractState,
        callbackId: correspondingDbTemplate.callbackId,
        name: null,
        status: contractTemplate
          ? {
              timestamp: Number(contractTemplate.status.timestamp),
              value: Number(contractTemplate.status.value),
            }
          : {},
        index: contractTemplate ? Number(contractTemplate.index) : -1,
        sessions: [],
        data: {},
      });
    }
  }

  // handle unpublished template
  for (let dbTemplate of dbTemplates) {
    if (
      dbTemplate.contractState != 'none' &&
      dbTemplate.contractState != 'creating'
    )
      continue;

    templateList.push({
      templateHash: dbTemplate.hash,
      visibility: dbTemplate.visibility,
      contractState: dbTemplate.contractState,
      callbackId: dbTemplate.callbackId,
      name: null,
      status: {},
      index: -1,
      sessions: [],
      data: {},
    });
  }

  // retrieve data
  const CONCURRENCY_LIMIT = 20;
  await bluebirdPromise.map(
    Array.from({ length: templateList.length }, (_, i) => i), // Create an array from 0 to templateNumber-1
    async (i) => {
      const data = await getJsonDataFromCID(
        hashToCID(templateList[i].templateHash)
      );
      templateList[i].name = data.name;
      templateList[i].data = data;
    },
    { concurrency: CONCURRENCY_LIMIT }
  );

  // retrieve sessions
  const sessions = (
    await getSessionsForTemplates(
      templateList.map((template) => template.templateHash)
    )
  ).data;

  for (let session of sessions) {
    const templateIndex = templateList.findIndex(
      (template) =>
        template.templateHash.replace('0x', '') == session.templateHash
    );
    templateList[templateIndex].sessions.push(session);
  }

  // filter multiple membercard
  // });
  let foundMembership = false;
  templateList = templateList
    .reverse()
    .filter((template) => {
      if (template.data.badgeCategory === 'Membership') {
        if (!foundMembership) {
          foundMembership = true;
          return true;
        }
        return false;
      }
      return true;
    })
    .reverse();

  return templateList;
}

export async function refreshSessionsForTemplates(templates) {
  const cleanTemplates = templates.map((template) => {
    template.sessions = [];
    return template;
  });

  const sessions = (
    await getSessionsForTemplates(
      cleanTemplates.map((template) => template.templateHash)
    )
  ).data;

  for (let session of sessions) {
    const templateIndex = cleanTemplates.findIndex(
      (template) =>
        template.templateHash.replace('0x', '') == session.templateHash
    );
    cleanTemplates[templateIndex].sessions.push(session);
  }

  return cleanTemplates;
}

// export async function getTemplateList(addr, type) {
//   const category = [
//     'Basic',
//     'Community',
//     'Participation',
//     'Membership',
//     'Role',
//   ].indexOf(type);
//   if (category === -1) throw 'Bad type for template list';

//   const templateList = (
//     await mydidContract.methods.getIssuerTemplates(addr, category).call()
//   ).reverse();
//   const CONCURRENCY_LIMIT = 20;

//   let templates = await bluebirdPromise.map(
//     Array.from({ length: templateList.length }, (_, i) => i), // Create an array from 0 to templateNumber-1
//     async (i) => {
//       const template = templateList[i];
//       if (
//         template.templateHash !=
//         '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
//       ) {
//         try {
//           const data = await getJsonDataFromCID(
//             hashToCID(template.templateHash)
//           );
//           return {
//             templateHash: template.templateHash,
//             name: template.name,
//             status: Number(template.status),
//             index: Number(template.index),
//             sessions: [],
//             data,
//           };
//         } catch (e) {
//           console.log(e);
//           return null;
//         }
//       } else return null;
//     },
//     { concurrency: CONCURRENCY_LIMIT } // Only CONCURRENCY_LIMIT promises will be pending at the same time
//   );

//   templates = templates.filter((el) => el != null);

//   // retrieve badge bot sessions
//   const sessions = (
//     await getSessionsForTemplates(
//       templates.map((template) => template.templateHash)
//     )
//   ).data;

//   for (let session of sessions) {
//     const templateIndex = templates.findIndex(
//       (template) =>
//         template.templateHash.replace('0x', '') == session.templateHash
//     );
//     templates[templateIndex].sessions.push(session);
//   }

//   if (type == 'Participation') {
//     return templates.reduce(
//       (acc, item) => {
//         acc[item.data.badgeCategory == 'Participation' ? 0 : 1].push(item);
//         return acc;
//       },
//       [[], []]
//     );
//   }

//   return templates;
// }

export async function createMydidTransaction(from, methodName, ...args) {
  const inputData = mydidContract.methods[methodName](...args).encodeABI();

  const gasPrice = parseInt(await web3.eth.getGasPrice());
  const estimateGas = await mydidContract.methods[methodName](
    ...args
  ).estimateGas({ from });

  const rawTransaction = {
    from,
    to: mydidContract.options.address,
    value: '0x0',
    gasPrice: '0x' + gasPrice.toString(16),
    gas: '0x' + Math.round(Number(estimateGas) * 1.01).toString(16),
    data: inputData,
    chainId: process.env.VUE_APP_CHAIN_ID,
  };

  return rawTransaction;
}

export async function getDID(addr) {
  return await mydidContract.methods.getDID(addr).call();
}

export async function isIssuer(addr) {
  return await mydidContract.methods
    .hasRole(createKeccakHash('keccak256').update('ISSUER_ROLE').digest(), addr)
    .call();
}

export async function isBroadcaster(addr) {
  return await mydidContract.methods
    .hasRole(
      '0x' +
        createKeccakHash('keccak256').update('BROADCASTER_ROLE').digest('hex'),
      addr
    )
    .call();
}

export async function isAdmin(addr) {
  const adminAddr = await mydidContract.methods.owner().call();
  return addr == adminAddr;
}

export async function getVCStatus(hash) {
  return await mydidContract.methods.vcs(hash).call();
}

export async function getIssuerCategory(addr) {
  return await mydidContract.methods.issuerCategory(addr).call();
}

export async function getPublicVCsByType(addr, type) {
  const vcNumber = await mydidContract.methods
    .getLengthOfPublicVcsByType(addr, type)
    .call();
  let vcs = [];
  for (var i = 0; i < vcNumber; i++) {
    const vc = await mydidContract.methods
      .publicVcsByType(addr, type, i)
      .call();
    vcs.push(vc);
  }
  return vcs;
}
