<template>
  <div class="flex justify-center">
    <div class="bg-white rounded-3xl border p-4">
      <qrcode-vue
        v-if="qrCodeData"
        :value="qrCodeData"
        :size="300"
        level="H"
        :margin="1"
      />
      <div
        v-else
        class="flex items-center justify-center h-[300px] w-[300px] bg-gray-50 rounded-lg"
      >
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="150"
          :width="150"
          :speed="1.5"
        />
      </div>
      <div class="flex justify-center items-center mt-5">
        <icon name="scan" class="w-4 fill-black mr-2" />
        <p class="font-bold">{{ $t('other.nostrScanTxt') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, provide, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { detect } from 'detect-browser';
import { toChecksumAddress } from 'ethereum-checksum-address';
import jwt from 'jsonwebtoken';
import EventsBus from '../plugins/eventBus';

export default {
  props: { signup: Boolean },
  setup(props, context) {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const store = useStore();
    const route = useRoute();
    const { eventBus } = EventsBus();

    const nostr = inject('nostr');
    const nostrVerifyLoading = ref(false);
    const qrCodeData = ref(null);

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);

    if (!nostr.publicKey) nostr.createSession();

    api.createAuthChallenge().then((res) => {
      const { challenge } = res.data;
      const browser = detect();

      let data = {
        type: 'NostrConnect',
        challenge,
        nostrId: nostr.publicKey,
        name: process.env.VUE_APP_NOSTR_URL,
        image: process.env.VUE_APP_EXTERNAL_LOGO_URL,
        browser: `${
          browser.name.charAt(0).toUpperCase() + browser.name.slice(1)
        } (${browser.os})`,
        signup: props.signup,
      };

      api.signNostrChallenge(data).then((res) => {
        data.signature = res.data.signature;

        const deepLink =
          'https://mydid.link?data=' + encodeURIComponent(JSON.stringify(data));

        api
          .shortenLink('/?data=' + encodeURIComponent(deepLink))
          .then((res) => {
            qrCodeData.value = res.data.dynamicLink;
          });
      });
    });

    watch(
      () => eventBus.value.get('nostr'),
      async (val) => {
        const [event] = val ?? [];
        const [eventType, eventData, eventPublicKey] = event;

        if (eventType == 'disconnect') {
          cookies.remove('token');
          store.commit('resetIssuerState');
          disconnectNostr();
        } else if (eventType == 'connect') {
          nostrVerifyLoading.value = true;

          const vp = eventData.verifiablePresentation;

          // send VP to server and retrieve session token
          let token = null;
          try {
            token = (await api.submitAuthVP(vp)).data.token;
          } catch (err) {
            console.log('Bad VP from Nostr :', err);
            store.commit('resetIssuerState');
            store.commit('setIssuerConnectionDisplay', false);
            disconnectNostr();
            nostrVerifyLoading.value = false;
            return;
          }

          const authenticationKeyVc =
            vp.verifiableCredential[
              vp.verifiableCredential.findIndex(
                (el) =>
                  el.credentialSubject.award &&
                  el.credentialSubject.award.startsWith('authenticationKey;')
              )
            ];
          const authenticationKey =
            authenticationKeyVc.credentialSubject.award.split(
              'authenticationKey; '
            )[1];

          const issuerDid = jwt.decode(token).did;
          const currentIssuerAddress = utils.didToAddress(issuerDid);
          nostr.validateUser(eventPublicKey);

          api.isIssuerRegistered(issuerDid).then((res) => {
            if (res.data.status) {
              store.commit('setCurrentIssuerDid', issuerDid);
              store.commit(
                'setCurrentAddress',
                toChecksumAddress(currentIssuerAddress)
              );
              store.commit(
                'setCurrentPublicKeyBase58',
                issuerDid.split(':')[2]
              );
              cookies.set('token', token);
              cookies.set('nostr_key', nostr.privateKey);
              cookies.set('nostr_user_key', nostr.userPublicKey);
              nostrVerifyLoading.value = false;

              context.emit('issuerConnected');
              store.dispatch('getTemplateLists');
              store.dispatch('getIssuerInfo');
            } else {
              store.commit('resetIssuerState');
              store.commit(
                'setCurrentAddress',
                toChecksumAddress(currentIssuerAddress)
              );

              store.commit(
                'setCurrentPublicKeyBase58',
                issuerDid.split(':')[2]
              );
              cookies.set('token_tmp', token);
              cookies.set('nostr_key', nostr.privateKey);
              cookies.set('nostr_user_key', nostr.userPublicKey);
              cookies.set('authentication_key', authenticationKey);
              store.commit('setIssuerConnectionDisplay', false);
              nostrVerifyLoading.value = false;
              context.emit('addressConnected');
            }
          });
        }
      }
    );

    async function disconnectNostr() {
      await nostr.disconnectSession();
    }

    return {
      route,
      nostrVerifyLoading,
      currentIssuerDid,
      currentAddress,
      qrCodeData,
    };
  },
};
</script>
