import { createStore } from 'vuex';
import {
  getTemplateListNew,
  getIssuerDetails,
  refreshSessionsForTemplates,
} from '../plugins/utils/mydid/index';

const store = createStore({
  state: {
    currentIssuerDid: null,
    currentIssuerInfo: null,
    currentAddress: null,
    currentPublicKeyBase58: null,
    currentChainId: null,
    isCurrentChainIdValid: false,

    displayIssuerConnection: false,
    displayVerticalBars: true,
    tourOngoing: false,

    templateListLoading: null,
    basicTemplateList: null,
    communityTemplateList: null,
    participationTemplateList: null,
    ticketTemplateList: null,
    membershipTemplateList: null,
    roleTemplateList: null,
  },
  mutations: {
    setCurrentIssuerDid(state, payload) {
      state.currentIssuerDid = payload;
      state.displayIssuerConnection = false;
    },
    setCurrentIssuerInfo(state, payload) {
      state.currentIssuerInfo = payload;
    },
    setCurrentAddress(state, payload) {
      state.currentAddress = payload;
    },
    setCurrentPublicKeyBase58(state, payload) {
      state.currentPublicKeyBase58 = payload;
    },
    setCurrentChainId(state, payload) {
      state.currentChainId = payload;
      state.isCurrentChainIdValid =
        payload == Number(process.env.VUE_APP_CHAIN_ID);
    },
    resetIssuerState(state) {
      state.currentIssuerDid = null;
      state.currentAddress = null;
      state.currentPublicKeyBase58 = null;
      state.currentIssuerInfo = null;
      state.currentChainId = null;
      state.isCurrentChainIdValid = false;
    },
    setIssuerConnectionDisplay(state, payload) {
      state.displayIssuerConnection = payload;
    },
    setVerticalBarsDisplay(state, payload) {
      state.displayVerticalBars = payload;
    },
    setTourOngoing(state, payload) {
      state.tourOngoing = payload;
    },
    setTemplateListLoading(state, payload) {
      state.templateListLoading = payload;
    },
    setBasicTemplateList(state, payload) {
      state.basicTemplateList = payload;
    },
    setCommunityTemplateList(state, payload) {
      state.communityTemplateList = payload;
    },
    setParticipationTemplateList(state, payload) {
      state.participationTemplateList = payload;
    },
    setTicketTemplateList(state, payload) {
      state.ticketTemplateList = payload;
    },
    setMembershipTemplateList(state, payload) {
      state.membershipTemplateList = payload;
    },
    setRoleTemplateList(state, payload) {
      state.roleTemplateList = payload;
    },
  },
  actions: {
    async getIssuerInfo({ commit, state }) {
      console.log('getIssuerInfo triggered');
      const issuerInfo = await getIssuerDetails(state.currentIssuerDid);
      commit('setCurrentIssuerInfo', issuerInfo);
    },
    async getTemplateLists({ commit, state }) {
      console.log('getTemplateLists triggered');
      commit('setTemplateListLoading', true);
      const list = await getTemplateListNew(state.currentAddress);

      commit(
        'setBasicTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Basic')
      );
      commit(
        'setCommunityTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Community')
      );
      commit(
        'setParticipationTemplateList',
        list.filter(
          (template) => template.data.badgeCategory == 'Participation'
        )
      );
      commit(
        'setTicketTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Ticket')
      );
      commit(
        'setMembershipTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Membership')
      );
      commit(
        'setRoleTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Role')
      );

      commit('setTemplateListLoading', false);
    },
    async refreshTemplateSessions({ commit, state }) {
      console.log('refreshTemplateSessions triggered');
      const allTemplates = [
        ...state.basicTemplateList,
        ...state.communityTemplateList,
        ...state.participationTemplateList,
        ...state.ticketTemplateList,
        ...state.membershipTemplateList,
        ...state.roleTemplateList,
      ];
      const list = await refreshSessionsForTemplates(allTemplates);

      commit(
        'setBasicTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Basic')
      );
      commit(
        'setCommunityTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Community')
      );
      commit(
        'setParticipationTemplateList',
        list.filter(
          (template) => template.data.badgeCategory == 'Participation'
        )
      );
      commit(
        'setTicketTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Ticket')
      );
      commit(
        'setMembershipTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Membership')
      );
      commit(
        'setRoleTemplateList',
        list.filter((template) => template.data.badgeCategory == 'Role')
      );
    },
  },
  modules: {},
  getters: {
    getCurrentIssuerDid(state) {
      return state.currentIssuerDid;
    },
    getCurrentIssuerInfo(state) {
      return state.currentIssuerInfo;
    },
    getCurrentAddress(state) {
      return state.currentAddress;
    },
    getCurrentPublicKeyBase58(state) {
      return state.currentPublicKeyBase58;
    },
    getCurrentChainId(state) {
      return state.currentChainId;
    },
    isCurrentChaindIdValid(state) {
      return state.isCurrentChainIdValid;
    },
    isIssuerConnectionDisplayed(state) {
      return state.displayIssuerConnection;
    },
    areVerticalBarsDisplayed(state) {
      return state.displayVerticalBars;
    },
    isTourOngoing(state) {
      return state.tourOngoing;
    },
    getTemplateListLoading(state) {
      return state.templateListLoading;
    },
    getBasicTemplateList(state) {
      return state.basicTemplateList;
    },
    getCommunityTemplateList(state) {
      return state.communityTemplateList;
    },
    getParticipationTemplateList(state) {
      return state.participationTemplateList;
    },
    getTicketTemplateList(state) {
      return state.ticketTemplateList;
    },
    getMembershipTemplateList(state) {
      return state.membershipTemplateList;
    },
    getRoleTemplateList(state) {
      return state.roleTemplateList;
    },
  },
});

export default store;
