<template>
  <div class="flex relative">
    <div v-if="!showConnexion" class="fixed left-0 top-0 h-screen w-1/2 z-0">
      <div class="w-full h-full overflow-hidden relative">
        <div class="w-full h-full bg-black bg-opacity-50 absolute"></div>
        <img
          :src="require('/assets/images/onboarding_home.webp')"
          class="w-full h-full object-cover"
        />
      </div>
    </div>
    <div
      v-if="!showConnexion"
      class="flex-none w-5/12 min-h-screen filter drop-shadow-2xl overflow-auto"
    >
      <div class="flex p-10">
        <img
          :src="require('/assets/images/small_mydid.png')"
          alt=""
          class="w-10 self-center mr-2"
        /><img
          :src="require('/assets/images/mydid.png')"
          alt=""
          class="w-40 self-center"
        />
      </div>
    </div>
    <div
      class="flex-1 min-h-screen overflow-auto z-10"
      style="padding-left: -20px"
    >
      <div
        class="flex h-full w-full shadow-md bg-white justify-center"
        :class="{
          'shadow-md rounded-l-[50px] border items-center': !showConnexion,
        }"
      >
        <div v-if="!showConnexion" class="text-center">
          <p class="text-4xl leadin leading-12 font-bold mb-2">
            {{ $t('signin.welcomeTxt') }}
          </p>
          <p class="text-4xl leadin leading-12 font-bold mb-16">
            {{ $t('signin.welcomeTxtBis') }}
          </p>
          <nostr-handler
            :signup="isSignup"
            @issuerConnected="router.push('/')"
            @addressConnected="router.push('/signup?connected=1')"
          ></nostr-handler>
          <div class="flex w-full py-5 justify-center items-center gap-4">
            <div class="w-24 shrink border-b"></div>
            <div class="text-center font-bold">
              {{ $t('signin.orTxt') }}
            </div>
            <div class="w-24 shrink border-b"></div>
          </div>
          <button
            @click="
              checkSession() && (showConnexion = true) && (isSignup = true)
            "
            class="bg-secondary text-white rounded-lg cursor-pointer text-lg py-3 px-10"
          >
            {{ $t('signin.createButton') }}
          </button>
        </div>
        <div v-else>
          <div class="flex w-screen h-28 items-center px-12">
            <div class="flex">
              <img
                :src="require('/assets/images/small_mydid.png')"
                alt=""
                class="w-8 self-center mr-2"
              /><img
                :src="require('/assets/images//mydid-logo.png')"
                alt=""
                class="w-32 self-center"
              />
            </div>
            <div class="grow"></div>

            <p
              @click="
                () => {
                  showConnexion = false;
                  isSignup = false;
                }
              "
              class="text-lg font-semibold text-gray-400 cursor-pointer"
            >
              <icon name="cross" class="fill-gray-600 mr-1.5" />
            </p>
          </div>
          <p class="text-xl text-center font-bold mb-10 mt-16">
            <icon name="account" class="mr-1.5" />{{
              isSignup ? $t('signin.signupTxt') : $t('signin.signinTxt')
            }}
          </p>
          <nostr-handler
            :signup="isSignup"
            @issuerConnected="router.push('/')"
            @addressConnected="router.push('/signup?connected=1')"
          ></nostr-handler>
          <div class="flex justify-center mt-10">
            <div class="w-[334px] px-3">
              <p class="font-bold">
                {{
                  isSignup
                    ? $t('signin.signinWelcomeTxt')
                    : $t('signin.signinWelcomeBackTxt')
                }}
              </p>
              <p>
                {{
                  isSignup
                    ? $t('signin.signinWelcomeTxtBis')
                    : $t('signin.signinWelcomeBackTxtBis')
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const cookies = inject('cookies');
    const nostr = inject('nostr');

    const showConnexion = ref(false);
    const isSignup = ref(false);

    if (route.query.new == '1') {
      showConnexion.value = true;
      isSignup.value = true;
    }

    function checkSession() {
      if (!cookies.get('token')) return true;
      window.location.href = '/';
    }

    return { showConnexion, router, isSignup, nostr, checkSession };
  },
};
</script>
